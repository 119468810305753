<template>
  <v-app id="inspire">
    <j-login-dialog v-if="$store.state.loginDialog.display"></j-login-dialog>
    <j-dialog-box v-if="this.$store.state.dialog.display" v-bind="this.$store.state.dialog" ></j-dialog-box>
    <j-loader v-if="this.$store.state.status.loading || this.$store.state.auth.status == 'loading' || this.$store.state.user.status == 'loading' || this.$store.state.register.status == 'loading'"></j-loader>
    <transition name="popup">
      <j-pop-up v-if="$store.state.popup.display" v-bind="this.$store.state.popup"></j-pop-up>
    </transition>
    <v-expand-transition>
      <j-banner-infos v-if="$store.state.bannerInfos.display" v-bind="this.$store.state.bannerInfos"></j-banner-infos>
    </v-expand-transition>
    <v-slide-y-reverse-transition>
      <j-collectives-infos class="collectives-mobile" v-if="$store.state.collectivesInfos.display" v-bind="this.$store.state.collectivesInfos"></j-collectives-infos>
    </v-slide-y-reverse-transition>
    <v-dialog :value="$store.state.optinDialog.display" :width="['sm', 'xs'].includes($vuetify.breakpoint.name) ? '100vw':'80vw'" persistent>
      <j-personnal-infos v-if="$store.state.optinDialog.display" @close="$store.dispatch('close_optin')"></j-personnal-infos>
    </v-dialog>
    <j-drawer :contentNavigation="getContentNavigation" :logout="false" v-model="drawer" @input="drawer = $event"></j-drawer>

    <j-nav @drawer="drawer = !drawer"></j-nav>

    <v-main v-if="!authLoading">
      <v-container class="fill-height align-start pa-0" fluid >
        <transition name="fade">
          <router-view class="flex-grow-1" :key="$route.fullPath" />
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'
import { AUTH_LOGOUT } from "@/assets/jobtimise-common/store/actions/auth"
import { jobEden, collective, coach, collectiveInfos, demo, course, hiddenOffer, commonQuestions } from '@/../config'

export default {
  name: "app",
  components: {
  },
  data:()=>{
    return {
      authLoading: true,
      drawer: false
    }
  },
  created: async function() {
    document.title = this.$route.meta.title || 'MyJobAcademy Elearning'

    axios.interceptors.response.use(async response => {
      if(response.headers['new-token']){
        await this.$store.dispatch('update_token',response.headers['new-token'])
      }
      return response
    }, async (error) => {
      if (error.response.status === 401) {
        this.$store.dispatch('reset_pending')
        if (this.$store.getters.isProfileLoaded) await this.$store.dispatch(AUTH_LOGOUT)
        if(!['/','/login','/forgot-password'].includes(this.$router.currentRoute.path)) this.$router.push("/")
      }
      return Promise.reject(error)
    })
    if(this.$store.getters.isAuthenticated) {
      if(collectiveInfos) this.$store.dispatch('open_collectives_infos',{message:"📅 Participez à un atelier collectif !",cb:'/collectives'})
      if(this.$store.getters.isProfileLoaded && !this.$store.getters.getProfile.optin) this.$store.dispatch('open_optin')
    }
    this.authLoading = false
  },
  watch: {
    '$route' (to) {
      document.title = to.meta.title || 'MyJobAcademy Elearning'
    }
  },
  computed: {
    getContentNavigation() {
      return [
        { icon : 'poll', link: '/user/dashboard', text: 'Votre tableau de bord', isActive: this.$store.getters.isAuthenticated },
        { icon : 'folder', link: '/user/documents', text: 'Vos documents', isActive: this.$store.getters.isAuthenticated},
        { type: 'separator', text: 'Cours', isActive: this.$store.getters.isAuthenticated },
        { icon : 'podium-gold', link: '/journeys', text: 'Vos parcours', isActive: this.$store.getters.isAuthenticated },
        { icon : 'school', link: '/courses', text: 'Formations', isActive: this.$store.getters.isAuthenticated && this.$store.getters.isCustomer && course },
        { icon : 'camcorder', link: '/collectives', text: 'Ateliers collectifs', isActive: this.$store.getters.isAuthenticated && this.$store.getters.isCustomer && collective },
        { type: 'separator', text: 'Outils', isActive: this.$store.getters.isAuthenticated },
        { icon : 'briefcase', link: `/user/job-applications/${this.$store.getters.getUserId}`, text: 'Suivre vos candidatures', isActive: this.$store.getters.isAuthenticated },
        { icon : 'pen', link: '/generator/resumes', text: 'Créer votre CV', isActive: this.$store.getters.isAuthenticated },
        { type: 'separator', text: 'Entretien', isActive: this.$store.getters.isAuthenticated },
        { icon : 'message-text', link: '/generator/introductions', text: 'Préparer votre présentation', isActive: this.$store.getters.isAuthenticated },
        { icon : 'comment-question-outline', link: '/generator/questions', text: 'Anticiper les questions', isActive: this.$store.getters.isAuthenticated && commonQuestions},
        { type: 'separator', text: 'Ressources', isActive: this.$store.getters.isAuthenticated },
        { icon : 'account-box', link: '/cv', text: 'Nos CV', isActive: this.$store.getters.isAuthenticated },
        { icon : 'file-document', link: '/coverletters', text: 'Nos lettres de motivation', isActive: this.$store.getters.isAuthenticated },
        { icon : 'information', link: '/hiddenoffers', text: 'Les annonces cachées', isActive: this.$store.getters.isAuthenticated && hiddenOffer },
        { icon : 'account-tie', link: '/coaches', text: 'Nos coachs', isActive: coach },
        { icon : 'briefcase', link: '/orientation', text: 'JobEden', isActive: jobEden },
        { link: '/orientation/all', text: 'Mes bilans', isActive: jobEden && this.$store.getters.isAuthenticated },
        { type: 'logout', isActive: this.$store.getters.isAuthenticated },
        { type: 'demo', isActive: demo && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin}
      ]
    }
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;700&display=swap');

@import 'assets/jobtimise-common/scss/style.scss';

#inspire {
  width: 100%;
  font-family: $titleFont, Helvetica, Arial, sans-serif;
}
.v-application--wrap{
  background: $mainGradient;
}
.collectives-mobile {
  @include noPhone {
    display: none;
  }
}
</style>
