import AccountSection from './content/AccountSection'
import SessionMiniature from './content/SessionMiniature'
import SessionProgress from './content/SessionProgress'
import SessionNavBar from './content/SessionNavBar'
import CourseContent from './content/CourseContent'
import CourseDetail from './content/CourseDetail'
import CourseCard from './content/CourseCard'
import JourneyCard from './content/JourneyCard'
import OfferCard from './content/OfferCard'
import Question from './content/Question'
import Quiz from './content/Quiz'
import AdsBanner from './content/AdsBanner'
import RessourcesCarousel from './content/RessourcesCarousel'
import WhyJoinUs from './content/WhyJoinUs'
import Picture from './content/Picture'
import MailEdit from './content/MailEdit'
import StepperLabels from './content/StepperLabels'
import ChatEngineInjector from './content/ChatEngineInjector'
import PersonnalInfos from './content/PersonnalInfos'
// Payment
import SubscriptionsChoice from './payment/SubscriptionsChoice'
// Generator 
import ResumeExperiences from './generator/resume/form/Experiences'
import ResumeEducations from './generator/resume/form/Educations'
import ResumeFormList from './generator/resume/form/List'
import ResumeFormSkills from './generator/resume/form/Skills'
import ResumeFormDetails from './generator/resume/form/Details'
import ResumeFormHelper from './generator/resume/form/Helper'
import ResumeTextEditor from './generator/resume/form/TextEditor'
import ResumeTimeline from './generator/resume/Timeline'
import ResumeList from './generator/resume/List'
import ResumeDetails from './generator/resume/Details'
import ResumeProfile from './generator/resume/Profile'
import ResumeToolsbar from './generator/resume/ui/Toolsbar'
import ResumeHeaderResize from './generator/resume/ui/HeaderResize'
import ResumeLean from './generator/resume/ui/Lean'
import ResumeV1 from './generator/resume/templates/v_1'
import ResumeV2 from './generator/resume/templates/v_2'
import ResumeV3 from './generator/resume/templates/v_3'
import ResumeV4 from './generator/resume/templates/v_4'
import ResumeV5 from './generator/resume/templates/v_5'
// Generator 
import IntroductionParagraphe from './generator/introduction/Paragraphe'
// Externals
import { CircleStencil  } from 'vue-advanced-cropper'
// Dataviz
import AnimatedLoader from './dataviz/AnimatedLoader'

export default {
    install(Vue){
        Vue.mixin({
            created() {
                const jcomp = {
                  CircleStencil,AccountSection,SessionMiniature,SessionProgress,SessionNavBar,CourseContent,CourseDetail,CourseCard,OfferCard,Question,Quiz,SubscriptionsChoice,JourneyCard,AdsBanner,RessourcesCarousel,WhyJoinUs,Picture,MailEdit,StepperLabels,ChatEngineInjector,PersonnalInfos,
                  // Resume
                  ResumeExperiences,ResumeEducations,ResumeFormDetails,ResumeFormHelper,ResumeTextEditor,ResumeTimeline,ResumeFormList,ResumeFormSkills,ResumeList,ResumeDetails,ResumeProfile,ResumeToolsbar,ResumeHeaderResize,ResumeLean,ResumeV1,ResumeV2,ResumeV3,ResumeV4,ResumeV5,
                  // Introduction
                  IntroductionParagraphe,
                  // Dataviz
                  AnimatedLoader
                }
                for(let key in jcomp){
                    Vue.component('j'+key,jcomp[key])
                }
            }
        })
    }
}
